<template lang="pug">
  article.terms
    div(v-if="license.version === '8'")
      h1 METACOM 8 NUTZUNGSBEDINGUNGEN

      :markdown-it
        Die Software einschließlich des Bildmaterials auf der METACOM 8 DVD bzw. in der METACOM 8 Downloaddatei ist urheberrechtlich geschützt. Sie haben jedoch mit dem Lizenzkauf das Recht zur Nutzung erworben.

        Wenn Sie als privat handelnde **Einzelperson** Lizenznehmer einer METACOM 8 Einzellizenz sind, können Sie diese auf jedem Gerät nutzen, das Ihnen gehört oder in Ihrem Besitz ist. Geräte am Arbeitsplatz sind eingeschlossen, wenn sie ausschließlich von Ihnen benutzt werden oder wenn Sie sicherstellen können, dass die Lizenz nur von Ihnen genutzt werden kann (z.B. durch einen eigenen passwortgeschützten Benutzerbereich, wenn andere Personen Zugriff auf das Gerät haben).

        Wenn Sie als **Freiberufler**, **Unternehmen**, **Einrichtung oder Behörde** (nachstehend Gesellschaft) Lizenznehmer einer oder mehrerer METACOM 8 Lizenzen sind, können Sie jede Lizenz entweder (a) von einer einzelnen Person Ihrer Gesellschaft auf  einem oder mehreren von dieser Person genutzten Geräten, die Ihnen gehören oder in Ihrem Besitz sind, nutzen lassen, oder (b) von mehreren Personen Ihrer Gesellschaft auf einem einzelnen, geteilten Gerät, das Ihnen gehört oder in Ihrem Besitz ist. Zum Beispiel kann ein einzelner Nutzer METACOM 8 sowohl auf mehreren, ausschließlich von ihm benutzten Geräten nutzen oder mehrere Nutzer können METACOM 8 nacheinander auf ein und demselben Gerät nutzen. Klarstellend sei erwähnt, dass für jedes benutzte Gerät, das von mehreren Nutzern benutzt wird, eine separate Lizenz erforderlich ist.

        Serverlizenzen dürfen auch über einen Server zur Nutzung freigegeben werden, und zwar entsprechend der Lizenzgröße (a) an genau so vielen festgelegten Arbeitsplätzen oder (b) von genau so vielen festgelegten Nutzern an beliebigen Arbeitsplätzen oder (c) zur Nutzung von beliebig vielen Nutzern an beliebig vielen Arbeitsplätzen, wenn die Zahl der gleichzeitigen Zugriffe auf maximal 1/3 der Lizenzgröße beschränkt ist. Zugriffsbeschränkungen sind serverseitig zu regeln.

        Das digitale Bildmaterial auf der DVD bzw. in der Downloaddatei darf auf die Festplatte kopiert und beliebig verändert, jedoch nicht weitergegeben werden, es sei denn, der Empfänger verfügt ebenfalls über eine gültige METACOM 8 Lizenz. Nicht erlaubt ist die Herstellung von Kopien der DVD bzw. Downloaddatei – auch nicht in Form von Auszügen – und ihre Weitergabe, eine Sicherungskopie zu Backupzwecken darf erstellt werden. Eine Veröffentlichung der Daten in Form eigener digitaler und nicht digitaler Bildersammlungen ist ebenfalls nicht erlaubt, auch nicht in abgewandelter Form. Mit dem Erwerb eines Updates durch Tausch der alten Version gegen eine neue erlischt das Recht zur Nutzung der alten Version.

        Mit METACOM 8 erstelltes Material darf in gedruckter, nicht digitaler Form weitergegeben werden (a) an eine Person, die selbst über eine gültige METACOM 8 Lizenz verfügt oder (b) zur persönlichen Nutzung an eine Person, die von Ihnen im Rahmen Ihrer Tätigkeit klinisch, therapeutisch oder lehrend betreut wird.

        Das Wiedergeben der Bilder auf Internetseiten und in Druckerzeugnissen ist unter bestimmten Bedingungen erlaubt, erfordert in der Regel aber eine Genehmigung. Die Genehmigung wird erteilt, wenn die Bilder in begrenztem Umfang zur Illustration verwendet werden, ein Copyright-Hinweis ist anzubringen (METACOM Symbole © Annette Kitzinger). In diesem Rahmen dürfen Schulen, Kindertagesstätten und Einrichtungen für Menschen mit Behinderung nach Erwerb einer METACOM Lizenz auf ihren Webseiten sowie in jedem nicht kommerziell erstellten und vertriebenen Druckerzeugnis (z.B. Einladung, Plakat) bis zu 25 Symbole genehmigungsfrei veröffentlichen. Bei genehmigungsfreier Einbindung auf Webseiten dürfen die Symbole maximal 200×200 Pixel groß sein und bei Bedarf gegen andere Symbole ausgetauscht werden, wenn dadurch pro Jahr nicht mehr als 50 verschiedene Symbole veröffentlicht werden. Genehmigungsanfragen bitte per Mail an info (at) metacom-symbole (punkt) de.

    div(v-else-if="license.version === '9'")
      h1 Nutzungsbedingungen für METACOM 9 Desktop
      p Version November 2022

      :markdown-it
        ## Vorbemerkungen
        V.1 Der Kauf des Produkts **METACOM 9 Desktop** ist rechtlich gesehen der Erwerb eines Nutzungsrechts durch einen **Lizenznehmer**.

        V.2 Das **Nutzungsrecht** am Produkt METACOM 9 Desktop kann als Persönliche Lizenz oder als Pro-Lizenz erworben werden.

        V.3 Der Umfang des **Nutzungsrechts** und weitere rechtliche Randbedingungen ergeben sich aus diesen **Nutzungsbedingungen**, welche die Abschnitte A, B und C enthalten.

        V.4 Für **Lizenznehmer der Persönlichen Lizenz** sind die Abschnitte A und B vorgesehen.

        V.5 Für **Lizenznehmer der Pro-Lizenz** sind die Abschnitte A und C vorgesehen.

        ## A. Allgemeines
        ### A.1 Einführung
        A.1.1 METACOM 9 Desktop ist ein **Produkt**, bestehend
        - aus der digitalen **Symbolsammlung** METACOM 9
        - und der **Software** MetaSearch.

        A.1.2 Die Symbolsammlung enthält Symbole für die Unterstützte Kommunikation (UK). Ein Lizenznehmer des Nutzungsrechts am Produkt kann sich mit der Software die Symbolsammlung anzeigen lassen und damit arbeiten.

        A.1.3 Inhaberin der Rechte an der Symbolsammlung, einschließlich der Nutzungsrechte, ist Annette Kitzinger, Bundesstraße 26, 24988 Oeversee, Deutschland, nachfolgend nur als die „Herausgeberin” bezeichnet. Die Herausgeberin ist auch Inhaberin der Nutzungsrechte an der Software.

        ### A.2 Verantwortung des Lizenznehmers für den Zugang zu Symbolen
        Die Symbole der Symbolsammlung sollen den betroffenen Personen eine Kommunikation im Zusammenhang mit möglichst allen Lebenssachverhalten ermöglichen. Dies beinhaltet auch die Erörterung von gesellschaftlich kontroversen Themen wie z.B. Religion, Sexualität, Gewalt und damit zusammenhängenden Handlungen. Eine Zensur der Symbole durch die Herausgeberin findet grundsätzlich nicht statt. Jeder Lizenznehmer ist selbst dafür verantwortlich, ob und inwieweit von ihm betreute Personen oder andere Benutzer Zugang zu den Symbolen haben sollen.

        ### A.3 Nutzungsrechte
        A.3.1 Die Symbolsammlung ist urheberrechtlich geschützt. Die Vervielfältigung, Bearbeitung, Weitergabe und Veröffentlichung der Symbolsammlung oder der Symbole sind nur gestattet, soweit dies in den nachstehenden Bedingungen ausdrücklich angegeben ist.

        A.3.2 Software und Produkt sind urheberrechtlich geschützt. Die Vervielfältigung, Bearbeitung, Weitergabe und Veröffentlichung sind nur gestattet, soweit dies in den nachstehenden Bedingungen ausdrücklich angegeben ist.

        ### A.4 Das Nutzungsrecht des Lizenznehmers

        A.4.1 Die Herausgeberin räumt dem Lizenznehmer nur ein **nicht-ausschließliches** Nutzungsrecht am Produkt ein.

        A.4.2 Das Nutzungsrecht des Lizenznehmers umfasst grundsätzlich die **Arbeit mit dem Produkt**, wie in der Software vorgesehen.

        A.4.3 Das Nutzungsrecht des Lizenznehmers umfasst außerdem die **digitale Weitergabe**
        - von Symbolen oder Dateien mit Symbolen an andere Inhaber eines Nutzungsrechts am Produkt, und
        - von mit dem Produkt erzeugten Dokumentdateien mit der Dateiendung **„.metadf”**
        - von mit dem Produkt erzeugten Sammlungsdateien mit der Dateiendung **„.metacf”**.

        A.4.4 Der Lizenznehmer darf die Symbole außerdem in digitalen Medien (in digitaler Form) zugänglich machen, sofern folgende Bedingungen zusammen erfüllt sind:
        die Nutzung der digitalen Medien erfolgt unter Aufsicht des Lizenznehmers oder während eines Unterrichts desselben, und die Nutzung der digitalen Medien erfolgt ausschließlich durch Personen, die vom Lizenznehmer im Rahmen seiner Tätigkeit klinisch, therapeutisch oder lehrend betreut werden.

        A.4.5 Der Lizenznehmer darf die Symbole in digitalen Medien auch außerhalb der Dokumentdateien mit der Endung „.metadf” und der Sammlungsdateien „.metacf” für sich selbst zugänglich machen, z.B. Symbole in eBooks oder Vokabularen für den Eigengebrauch.

        A.4.6 Mit dem Produkt METACOM 9 Desktop erstelltes Material darf in **gedruckter** Form weitergegeben werden\
        a) an Personen, die selbst über ein Nutzungsrecht am Produkt METACOM 9 Desktop verfügen oder\
        b) zur persönlichen Nutzung an Personen, die vom Lizenznehmer im Rahmen seiner Tätigkeit klinisch, therapeutisch oder lehrend betreut werden.

        A.4.7 Der Lizenznehmer hat für die Weitergabe von Dateien oder gedrucktem Material darauf zu achten, dass er zur Weitergabe aller enthaltenen Bilder oder Symbole berechtigt ist. Auch Urheberrechte Dritter sind zu beachten, insbesondere bei Nutzung der Funktionen „Meine Symbole” und „Bild importieren”.

        ### A.5 Vom Nutzungsrecht ausgeschlossen
        A.5.1 Eine **öffentliche Wiedergabe** (Wiedergabe gerichtet an eine Mehrzahl von Mitgliedern der Öffentlichkeit) der Symbole in Form **gedruckter oder digitaler** Bildersammlungen ist nicht erlaubt, auch nicht in abgewandelter Form.

        A.5.2 Eine **öffentliche Zugänglichmachung** (Zugänglichmachung in einer Weise, dass es Mitgliedern der Öffentlichkeit von Orten und zu Zeiten ihrer Wahl zugänglich ist) der Symbole in Form **gedruckter oder digitaler** Bildersammlungen ist nicht erlaubt, auch nicht in abgewandelter Form.

        A.5.3 Nicht vom Nutzungsrecht umfasst und damit grundsätzlich nicht zulässig ist das Kopieren des Produkts, der Software und der Symbolsammlung, außer zum Zweck der Installation und zur Anfertigung eines Backups durch den Lizenznehmer.

        A.5.4 Nicht vom Nutzungsrecht umfasst und damit grundsätzlich nicht zulässig sind das **Übersetzen, Bearbeiten, Arrangieren und Umarbeiten** des Produkts, der Software und der Symbolsammlung, sowie eine Vervielfältigung der erzielten Ergebnisse.

        A.5.5 Nicht vom Nutzungsrecht umfasst und damit grundsätzlich nicht zulässig ist die Veränderung von mit der Software erstellten Dateien zum Zweck der **Überwindung von Dateizugangsbeschränkungen**. Dazu zählt auch die Veränderung von Dateiendungen.

        A.5.6 Nicht vom Nutzungsrecht umfasst und damit grundsätzlich nicht zulässig ist das Öffnen von mit der Software erzeugten Dokumentdateien mit der Dateiendung .metadf in anderen Programmen.

        A.5.7 Der Lizenznehmer darf keine **Unterlizenzen** erteilen. Der Lizenznehmer darf das Produkt oder Teile davon nicht vermieten, verleihen oder verleasen.

        A.5.8 Text und Data Mining (automatisierte Analyse von digitalen oder digitalisierten Werken, um daraus Informationen zu gewinnen) des Werkes, nämlich der Software, der Symbolsammlung und von Teilen derselben, sowie eine Vervielfältigung des Werkes für das Text und Data Mining sind nicht z

        A.5.9 Die zustimmungsfreien oder nicht abdingbaren Rechte des Lizenznehmers, soweit diese Rechte in einem Gesetz geregelt sind, insbesondere gemäß § 69d Absätze 2, 3 und 7 sowie § 69e Urheberrechtsgesetz, haben Vorrang und sollen durch diese Nutzungsbedingungen nicht eingeschränkt werden.

        ### A.6 Zulässige Ausnahmen für die Wiedergabe von Symbolen im Internet
        A.6.1 Der Lizenznehmer darf auf seiner Internetseite und in sozialen Medien Fotos einbinden, die lediglich die Verwendung der Symbole zeigen, die jedoch nicht dazu geeignet sind, Materialien mit Symbolen herzustellen. Erlaubt ist z. B. ein Foto das eine auf einem Tisch liegende Kommunikationstafel mit Symbolen zeigt. Nicht erlaubt ist z. B. ein Foto in der Qualität eines Scans der Kommunikationstafel oder eines Screenshots der Kommunikationstafel-Datei.

        A.6.2.1 Ist der Lizenznehmer des Nutzungsrechts eine Schule, eine Kindertagesstätte oder eine Einrichtung für Menschen mit Behinderung, dürfen nur auf der Internetseite des Lizenznehmers bis zu 25 Symbole genehmigungsfrei veröffentlicht werden. Dabei dürfen die Symbole maximal 250x250 Pixel groß sein und bei Bedarf gegen andere Symbole ausgetauscht werden, wenn dadurch pro Jahr nicht mehr als 50 verschiedene Symbole veröffentlicht werden. Ein Copyright-Hinweis ist anzubringen (METACOM Symbole © Annette Kitzinger).

        A.6.2.2 Die Veröffentlichung der Symbole gemäß A.6.2.1 ist insbesondere nicht zulässig auf Seiten des Lizenznehmers in sozialen Medien und in sozialen Medien generell. Soziale Medien sind beispielsweise Facebook, Instagram, WhatsApp, YouTube, LinkedIn, TikTok, Twitter.

        A.6.3 Bei veröffentlichten Symbolen sind folgende Veränderungen erlaubt: Änderung der Hintergrundfarbe, Kombination mehrerer unveränderter Symbole, Skalierung, Drehung und Spiegelung von Symbolen. Unzulässig sind hingegen: verzerrte Darstellungen, entstellende Kombinationen von Teilen aus mehreren Symbolen (z. B. Kopf und Körper aus verschiedenen Symbolen).

        A.6.4 Im Übrigen darf der Lizenznehmer Symbole aus der Symbolsammlung auf seiner Internetseite nur mit Genehmigung der Herausgeberin wiedergeben. Genehmigungsanfragen mit genauer Beschreibung der geplanten Veröffentlichungsart der Symbol-Anzahl und -Größe (alternativ Einsendung des Materials zur Ansicht) bitte per E-Mail an info@metacom-symbole.de.

        ### A.7 Zulässige Ausnahmen für die Weitergabe von Symbolen in Druckerzeugnissen

        A.7.1 Der Lizenznehmer darf in seinem nicht kommerziell erstellten und vertriebenen Druckerzeugnis Fotos einbinden, die lediglich die Verwendung der Symbole zeigen, die jedoch nicht dazu geeignet sind, Materialien mit Symbolen herzustellen. Erlaubt ist z. B. ein Foto das, eine auf einem Tisch liegende Kommunikationstafel mit Symbolen zeigt. Nicht erlaubt ist z. B. ein Foto in der Qualität eines Scans der Kommunikationstafel oder eines Screenshots der Kommunikationstafel-Datei.

        A.7.2 Ist der Lizenznehmer des Nutzungsrechts eine Schule, eine Kindertagesstätte oder eine Einrichtung für Menschen mit Behinderung, dürfen in jedem nicht kommerziell erstellten und vertriebenen Druckerzeugnis (z. B. Einladung, Plakat, Flyer) des Lizenznehmers bis zu 25 Symbole genehmigungsfrei veröffentlicht werden. Ein Copyright-Hinweis ist anzubringen (METACOM Symbole © Annette Kitzinger).

        A.7.3 Bei veröffentlichten Symbolen sind folgende Veränderungen erlaubt: Änderung der Hintergrundfarbe, Kombination mehrerer unveränderter Symbole, Skalierung, Drehung und Spiegelung von Symbolen. Unzulässig sind hingegen: verzerrte Darstellungen, entstellende Kombinationen von Teilen aus mehreren Symbolen (z. B. Kopf und Körper aus verschiedenen Symbolen).

        A.7.4 Im Übrigen darf der Lizenznehmer Symbole aus der Symbolsammlung in seinen Druckerzeugnissen nur mit Genehmigung der Herausgeberin wiedergeben. Genehmigungsanfragen mit genauer Beschreibung der geplanten Veröffentlichungsart der Symbol-Anzahl und -Größe (alternativ Einsendung des Materials zur Ansicht) bitte per E-Mail an info@metacom-symbole.de.

        ### A.8 Installation
        A.8.1 Nach Vertragsschluss erhält der Lizenznehmer Zugangsdaten für das Herunterladen des Produkts und zur eindeutigen Identifizierung der Lizenz. Anschließend ist eine Registrierung des Lizenznehmers mit den Zugangsdaten erforderlich. Danach kann das Produkt heruntergeladen werden.

        A.8.2 Der Vertrag kann zusätzlich die Übersendung eines oder mehrerer Installationsdatenträger (z.B. DVDs) beinhalten.

        A.8.3 Für die Einhaltung der Nutzungsbedingungen erforderliche, technische Zugangsbeschränkungen sind vom Lizenznehmer in der Installationsumgebung vorzunehmen oder zu veranlassen. Insbesondere hat der Lizenznehmer durch geeignete Zugangsbeschränkungen sicherzustellen, dass unbefugte Dritte keinen Zugriff auf das Produkt und deren Teile haben.

        A.8.4 Der Lizenznehmer darf Installationsdatenträger und Zugangsdaten nicht an Dritte weitergeben oder für Dritte zugänglich machen. Zulässig ist jedoch die Weitergabe der Installationsdatenträger und Zugangsdaten an vom Lizenznehmer mit der Installation oder Problemlösung beauftragte Personen, soweit es zur Installation oder Problemlösung erforderlich ist.

        ### A.9 Verkauf, Schenkung, Übergabe
        A.9.1 Erwirbt ein Wiederverkäufer das Produkt METACOM 9 Desktop und verkauft dieses an einen Dritten, so wird anschließend nur der Dritte Lizenznehmer des sich aus diesen Nutzungsbedingungen ergebenden Nutzungsrechts. Der Wiederverkäufer kann nur die Nutzungsrechte weitergeben, die er selbst erhalten hat. Der Dritte als Lizenznehmer kann sich deshalb nicht darauf berufen, dass ihn der Wiederverkäufer nicht über diese Nutzungsrechte informiert hat. Der Wiederverkäufer kann jedoch eigene vertragliche Leistungen oder Bedingungen ergänzen.

        A.9.2 Analog gilt der voranstehende erste Absatz für den Fall, dass ein Käufer Lizenznehmer ist und das Produkt an einen Rechtsnachfolger übergibt oder weiterverkauft. Auch hier kann der Lizenznehmer nur das Nutzungsrecht an den Rechtsnachfolger weitergeben, welches er vorher erworben hat. Bei Übergang des Nutzungsrechts vom bisherigen Lizenznehmer auf den Rechtsnachfolger erlischt das Nutzungsrecht auf Seiten des bisherigen Lizenznehmers. Nach dem Übergang des Nutzungsrechts auf den Rechtsnachfolger muss der bisherige Lizenznehmer das Produkt auf seinem Computer oder seinen Computern deinstallieren.

        A.9.3 Ebenfalls analog gilt der erste Absatz für den Fall, dass zwar kein Verkauf vorliegt, jedoch Käufer und Lizenznehmer in der Praxis ohnehin auseinanderfallen, wie bei einem Kauf mit anschließender Schenkung (z.B. Großeltern -> Eltern; Förderverein -> Schule) oder bei der Übergabe an eine abhängige organisatorische Einheit (z.B. Gemeinde -> Schule). Der Käufer wird selbst nicht Lizenznehmer. Der Lizenznehmer erhält nur das Nutzungsrecht, welches der Käufer zuvor erworben hat. Der Käufer behält kein Nutzungsrecht für sich.

        ### A.10 Neue Versionen
        Der Erwerb einer neuen Version des Produkts kann auch im Tausch gegen eine ältere Version des Produkts erfolgen, sofern von der Herausgeberin angeboten. Die neue Version gilt dann als Update der vorhandenen älteren Version. Als Update gilt auch der Erwerb einer Downloadversion zu einem Update-Preis. Mit dem Erwerb des Updates eines vorhandenen Produkts erlischt das Nutzungsrecht an der vor dem Aufspielen des Updates vorhandenen älteren Version des Produkts, so dass nach dem Erwerb des Updates nicht zwei Produkte, sondern weiterhin nur ein Produkt mit Nutzungsrecht vorliegt. Dies gilt unabhängig davon, ob das Update kostenlos oder kostenpflichtig erfolgt ist. Ein Update auf eine neue Version, bei vorhandener älterer Version, wird als solche gekennzeichnet sein.

        ## B. Zusätzliche Bestimmungen nur für die Persönliche Lizenz
        ### B.1 Lizenznehmer der Persönlichen Lizenz
        Die Persönliche Lizenz ist an eine natürliche Person als Lizenznehmer gekoppelt. Nur der Lizenznehmer darf das Produkt oder dessen Teile nutzen.

        ### B.2 Zusätzliche Nutzungsrechte
        B.2.1 Das Nutzungsrecht der Persönlichen Lizenz umfasst auch die Installation auf mehreren Computern. Voraussetzung ist, dass nur der Lizenznehmer Zugriff auf diese Computer hat.

        B.2.2 Die Symbole der Symbolsammlung METACOM 9 dürfen für persönliche Zwecke des Lizenznehmers verändert werden.

        ### B.3 Zusätzlich ausgeschlossene Nutzungsrechte
        Das Nutzungsrecht der Persönlichen Lizenz umfasst nicht die Nutzung des Produkts oder von dessen Teilen in vernetzten Installationsumgebungen und auf Terminalservern.

        ## C. Zusätzliche Bestimmungen nur für die Pro-Lizenz
        ### C.1 Lizenznehmer der Pro-Lizenz
        Die Pro-Lizenz soll erworben werden von Einrichtungen, Institutionen, juristischen Personen, Unternehmen, Einzelunternehmern.

        ### C.2 Lizenzgröße der Pro-Lizenz
        C.2.1 Zu jeder Pro-Lizenz gehört eine zwischen der Herausgeberin und dem Lizenznehmer vereinbarte Lizenzgröße. Die Lizenzgröße bestimmt die maximale Anzahl der zur Verfügung stehenden **Lizenzplätze**. Der Lizenznehmer darf frei entscheiden, ob er die Lizenzplätze Produktanwendern, Produktarbeitsplätzen oder gleichzeitigen Produktnutzungen zuweist.

        C.2.2 **Produktanwender** ist eine natürliche Person, die auf einem oder mehreren Geräten mit dem Produkt für Zwecke des Lizenznehmers arbeitet und hinsichtlich des Umgangs mit dem Produkt gegenüber dem Lizenznehmer weisungsgebunden ist. Jeder vom Lizenznehmer bestimmte Produktanwender belegt jeweils einen eigenen Lizenzplatz.

        C.2.3 **Produktarbeitsplatz** ist ein Gerät, an dem eine oder mehrere Personen für Zwecke des Lizenznehmers mit dem Produkt arbeiten können. Jeder vom Lizenznehmer bestimmte Produktarbeitsplatz belegt jeweils einen eigenen Lizenzplatz. Zur Produktbereitstellung verwendete Server bzw. Terminalserver sind keine Produktarbeitsplätze, Clients der Server zählen als Produktarbeitsplätze.

        C.2.4 **Gleichzeitige Produktnutzungen** sind auf Terminalservern des Lizenznehmers zur gleichen Zeit laufende Produktsitzungen (Nutzung des Produkts oder seiner Bestandteile) und sind demnach auf Terminalserverumgebungen beschränkt, die alle zur gleichen Zeit laufenden Produktnutzungen zählen und begrenzen. Als Maximum der gleichzeitigen Produktnutzungen sind 1/3 (ein Drittel) der hierfür zugewiesenen Lizenzplätze erlaubt.

        C.2.5 **Kombinationen und spätere Änderungen** in der Zuweisung der Lizenzplätze sind erlaubt. Der Lizenznehmer kann z. B. eine Lizenzgröße 60 so aufteilen:
        30 Lizenzplätze für 10 gleichzeitige Produktnutzungen auf einem Terminalserver, 30 Lizenzplätze für Arbeitsplätze mit lokaler Installation.

        C.2.6 Der Lizenznehmer ist für die Einrichtung und den Bestand von **Zugriffsbeschränkungen** verantwortlich, welche eine auf die Lizenzgröße beschränkte Benutzung sicherstellen.

        ## C.3 Zusätzliche Nutzungsrechte der Pro-Lizenz

        C.3.1 Das Nutzungsrecht der Pro-Lizenz schließt die Nutzung des Produkts oder von dessen Teilen in vernetzten Installationsumgebungen und auf Terminalservern ein.

        C.3.2 Die Symbole der Symbolsammlung METACOM 9 dürfen von den Produktanwendern und vom Lizenznehmer für deren persönliche Zwecke verändert werden.

</template>
<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {}
  },
  computed: {
    ...mapState('auth', { license: 'userData' })
  }
}
</script>

import UpgradeSessionAPI from '@/services/api/UpgradeSession'
import countryDefinitions from '@/lib/countries'
import licenseMixin from '@/mixins/license'
import Vue from 'vue'
import { DialogProgrammatic as Dialog } from 'buefy'
import { v4 as uuidv4 } from 'uuid'

const METACOM_SHOP_HOST = process.env.VUE_APP_SHOP_URL
const PRODUCT_ID_DVD = process.env.VUE_APP_SHOP_PRODUCT_ID_DVD
const PRODUCT_ID_PERSONAL_UPDATE =
  process.env.VUE_APP_SHOP_PRODUCT_ID_PERSONAL_UPDATE
const PRODUCT_ID_PERSONAL_FULL =
  process.env.VUE_APP_SHOP_PRODUCT_ID_PERSONAL_FULL
const PRODUCT_ID_PRO_UPDATE = process.env.VUE_APP_SHOP_PRODUCT_ID_PRO_UPDATE
const PRODUCT_ID_PRO_FULL = process.env.VUE_APP_SHOP_PRODUCT_ID_PRO_FULL

// STATE MACHINE
const ASSISTANT_STATES = {
  welcome: {
    on: {
      START: 'country_select',
      OFFER: 'offer_load',
      MANUAL: 'manual_order'
    },
    handle: {
      MANUAL ({ state, commit }) {
        commit('reset')
        state.purchaseType = 'reseller'
        state.toVersion = '9'
      },
      START ({ commit }) {
        commit('reset')
      },
      OFFER ({ commit }) {
        commit('reset')
      }
    }
  },
  country_select: {
    on: {
      DE: 'start',
      AT: 'start',
      CH: 'start',
      OTHER: 'country_select_other'
    },
    handle: {
      DE ({ state }) {
        state.billing.countryCode = 'DE'
      },
      AT ({ state }) {
        state.billing.countryCode = 'AT'
        state.dvdCount = 0
      },
      CH ({ state }) {
        state.billing.countryCode = 'CH'
        state.dvdCount = 0
      },
      OTHER ({ state }) {
        state.dvdCount = 0
      }
    }
  },
  country_select_other: {
    on: {
      SELECTED: 'start'
    }
  },
  start: {
    on: {
      NO: 'license_select',
      YES: 'update_eligibility'
    },
    handle: {
      NO ({ state }) {
        state.purchaseType = 'new'
        state.toVersion = '9'
      }
    }
  },
  update_eligibility: {
    on: {
      M7_OR_LESS: 'update_not_eligible',
      M8: 'license_login',
      M9: 'license_login'
    },

    handle: {
      M8 ({ state }) {
        state.purchaseType = 'upgrade'
        state.fromVersion = '8'
        state.toVersion = '9'
        state.authenticatedLicenses = state.authenticatedLicenses.filter(
          (d) => d.license.version === '8'
        )
      },
      M9 ({ state }) {
        state.purchaseType = 'extension'
        state.fromVersion = '9'
        state.toVersion = '9'
        state.licenseType = 'pro'
        state.authenticatedLicenses = state.authenticatedLicenses.filter(
          (d) => d.license.version === '9'
        )
      }
    }
  },
  update_not_eligible: {
    // Tell the user that his license cannot be updated, but a new license can be purchased
    on: {
      BUY: 'license_select'
    },
    handle: {
      BUY ({ state }) {
        state.purchaseType = 'new'
        state.toVersion = '9'
      }
    }
  },
  license_pro_calculator: {
    handle: {
      async COMPLETED ({ state, dispatch }) {
        await dispatch('maybeUpdateSessionProperties')

        if (state.purchaseType !== 'new') {
          return 'license_bind'
        } else {
          return 'summary'
        }
      }
    }
  },
  license_select: {
    // Select the kind of license to purchase
    on: {
      PERSONAL: 'license_select_dvd',
      PRO: 'license_pro_calculator'
    },
    handle: {
      async PERSONAL ({ state, getters, dispatch }) {
        state.licenseType = 'personal'
        state.seatCount = 1
        if (state.purchaseType === 'upgrade') {
          const shouldContinue = await new Promise((resolve) => {
            Dialog.confirm({
              type: 'is-primary',
              title: 'Nur für natürliche Personen',
              message:
                'Einrichtungen, Schulen, Kitas, Behörden, Unternehmen oder Vereine müssen Pro-Lizenzen erwerben, auch wenn es nur 1 nutzende Person gibt.<br/><br/>Gehen Sie auf „Abbrechen” um eine Pro-Lizenz auszuwählen.',
              confirmText: 'Fortfahren mit Persönlicher Lizenz',
              cancelText: 'Abbrechen',
              onConfirm: () => resolve(true),
              onCancel: () => resolve(false)
            })
          })
          if (!shouldContinue) {
            return false
          }
        }

        await dispatch('maybeUpdateSessionProperties')
      },
      async PRO ({ state, dispatch }) {
        state.licenseType = 'pro'
        await dispatch('maybeUpdateSessionProperties')
      }
    }
  },
  license_select_dvd: {
    handle: {
      async DVD ({ state, dispatch }) {
        state.dvdCount = 1
        await dispatch('maybeUpdateSessionProperties')
        if (state.purchaseType !== 'new') {
          return 'license_bind'
        } else {
          return 'summary'
        }
      },
      async NO_DVD ({ state, dispatch }) {
        state.dvdCount = 0
        await dispatch('maybeUpdateSessionProperties')
        if (state.purchaseType !== 'new') {
          return 'license_bind'
        } else {
          return 'summary'
        }
      }
    }
  },
  license_login: {
    handle: {
      async LOGGED_IN ({ state, getters, dispatch }) {
        await dispatch('createSession')
        if (state.purchaseType === 'upgrade') {
          state.seatCount = getters.totalOldLicenseSeats
        }

        if (
          state.purchaseType === 'extension' ||
          getters.totalOldLicenseSeats > 1
        ) {
          state.licenseType = 'pro'
          return 'license_pro_calculator'
        }

        return 'license_select'
      }
    }
  },

  license_bind: {
    // Bind an not yet bound license
    on: {
      COMPLETED: 'summary'
    }
  },
  offer_load: {
    on: {
      LOADED: 'summary'
    }
  },
  manual_order: {
    on: {
      SUBMIT: 'summary'
    }
  },
  summary: {
    on: {
      OFFER: 'offer_create',
      CART: 'add_to_cart'
    }
  },
  offer_create: {
    on: {
      CREATED: 'offer_created'
    }
  },
  offer_created: {
    handle: {
      RESET ({ commit }) {
        commit('reset')
        return false
      }
    }
  },

  add_to_cart: {
    async onEnter ({ dispatch, getters, state }, from) {
      if (
        state.purchaseType === 'extension' ||
        state.purchaseType === 'upgrade'
      ) {
        await dispatch('patchSession', {
          properties: getters.purchasedLicenseProperties
        })
      }
      dispatch('sendToShop')
    }
  }
}

// VUEX STORE
const state = {
  session: null,
  sessionToken: null,
  offerToken: null,

  createError: null,
  isLoadingCreate: false,

  createOfferError: null,
  isCreatingOffer: false,

  loadOfferError: null,
  isLoadingOffer: false,

  patchError: null,
  isLoadingPatch: false,

  // State
  currentState: 'welcome',
  history: [],

  // Session properties
  billing: {
    name: null,
    additional1: null,
    additional2: null,
    street: null,
    houseNumber: null,
    postcode: null,
    city: null,
    countryCode: 'DE',
    email: null,
    reference: null,
    note: null
  },
  shipToDifferentAddress: false,
  shipping: {
    name: null,
    additional1: null,
    additional2: null,
    street: null,
    houseNumber: null,
    postcode: null,
    city: null,
    countryCode: null
  },

  fromVersion: null,
  toVersion: null,

  purchaseType: null, // 'new', 'upgrade', 'extension'
  licenseType: null, // 'personal' / 'pro'

  seatCount: 0, // Purchased seats
  dvdCount: 0, // Purchased additional dvds

  authenticatedLicenses: [],
  resellerLicenses: [] // Purchasing multiple reseller licenses
}

// TODO: Refetch authenticatedLicenses once the user logs in with a session token

const getters = {
  isOffer: (state) => state.session?.status === 'offered' ?? false,
  isResellerOrder: (state) => state.purchaseType === 'reseller',
  canPurchaseDVDs: (state) => state.billing.countryCode === 'DE',
  hasSession: (state) => state.session != null,
  currentStateDefinition: (state) => ASSISTANT_STATES[state.currentState],
  totalOldLicenseSeats (state) {
    return state.authenticatedLicenses.reduce(
      (seats, data) => seats + data.license.properties.size,
      0
    )
  },
  orderHasShipping (state) {
    return state.dvdCount > 0
  },
  recommendedUpgradeLicenseClass (state, getters) {
    if (state.purchaseType !== 'upgrade') {
      return null
    }
    if (getters.totalOldLicenseSeats > 1) {
      return 'pro'
    } else {
      // Unbound single license: no recommendation
      if (state.authenticatedLicenses[0].license.status === 'unbound') {
        return null
      }

      // Bound single license with contact:
      if (
        state.authenticatedLicenses[0].license?.holder?.contact != null &&
        state.authenticatedLicenses[0].license?.holder?.contact?.length > 0
      ) {
        return 'pro'
      }

      return 'personal'
    }
  },
  /**
   * Sums all pro licenses to apply a cumulative discount
   */
  cartTotalProLicenseQuantity (state, getters) {
    const totalProLicenseQuantity = getters.shopProducts.reduce((sum, p) => {
      return p.class === 'pro' ? sum + p.quantity : sum
    }, 0)
    return totalProLicenseQuantity
  },
  cartProLicenseFactor (state, getters) {
    const factor = licenseMixin.methods.getPriceFactorForQuantity(
      getters.cartTotalProLicenseQuantity
    )
    return factor
  },
  cartProLicenseDiscount (state, getters) {
    const discount = licenseMixin.methods.getVolumeDiscountForTotalQuantity(
      getters.cartTotalProLicenseQuantity
    )
    return discount
  },
  /**
   * Returns the array of shop products resulting from the upgrade session
   */
  shopProducts (state, getters) {
    const getProduct = (isPro, isUpgrade, quantity) => ({
      sku: `M9-${isPro ? 'PRO' : 'PER'}${isUpgrade ? 'U' : ''}`,
      productType: 'license',
      productId: isUpgrade
        ? isPro
          ? PRODUCT_ID_PRO_UPDATE
          : PRODUCT_ID_PERSONAL_UPDATE
        : isPro
          ? PRODUCT_ID_PRO_FULL
          : PRODUCT_ID_PERSONAL_FULL,
      class: isPro ? 'pro' : 'personal',
      type: isUpgrade ? 'update' : 'full',
      quantity
    })

    const products = []
    // Integrate reseller licenses
    if (getters.isResellerOrder) {
      state.resellerLicenses.forEach((l) => {
        const p = getProduct(
          l.properties.class === 'pro',
          l.properties.purchaseType === 'full',
          l.properties.size
        )
        p.customerReference =
          l.customerReference ?? getters.getCustomerReferencePlaceholder(l.uid)
        p.licenseUID = l.uid
        p.cartUID = `${l.uid}-license`
        products.push(p)

        if (l.properties.dvdCount > 0) {
          products.push({
            productType: 'dvd',
            productId: PRODUCT_ID_DVD,
            sku: 'M9-DVD',
            quantity: l.properties.dvdCount,
            customerReference:
              l.customerReference ??
              getters.getCustomerReferencePlaceholder(l.uid),
            licenseUID: l.uid,
            cartUID: `${l.uid}-dvd`
          })
        }
      })

      return products
    }

    // License
    const isPro = state.licenseType === 'pro'
    const isUpgrade = state.purchaseType === 'upgrade'
    // const isExtension = state.purchaseType === 'extension'
    const oldLicenseSeats = getters.totalOldLicenseSeats

    const upgradeSeats = isUpgrade
      ? Math.min(oldLicenseSeats, state.seatCount)
      : 0
    const fullSeats = state.seatCount - upgradeSeats

    if (upgradeSeats > 0) {
      products.push(getProduct(isPro, true, upgradeSeats))
    }

    if (fullSeats > 0) {
      products.push(getProduct(isPro, false, fullSeats))
    }

    // DVDs
    if (state.dvdCount > 0) {
      products.push({
        productType: 'dvd',
        productId: PRODUCT_ID_DVD,
        sku: 'M9-DVD',
        quantity: state.dvdCount
      })
    }
    return products
  },
  cartVat (state) {
    return countryDefinitions[state.billing.countryCode].vat
  },
  cart (state, getters) {
    const contents = {
      lineItems: getters.cartLineItems,
      groupedLineItems: getters.groupedLineItems,
      totals: getters.cartTotals
    }
    return contents
  },
  cartLineItems (state, getters) {
    const shopProducts = getters.shopProducts
    return shopProducts.map((p) => {
      const vatRateForProduct = getters.cartVat
      // Use individual discounts for reseller licenses
      const relevantQuantity = getters.isResellerOrder
        ? p.quantity
        : getters.cartTotalProLicenseQuantity
      const factor =
        p.class === 'pro'
          ? licenseMixin.methods.getPriceFactorForQuantity(relevantQuantity)
          : 1
      const discount =
        p.class === 'pro'
          ? licenseMixin.methods.getVolumeDiscountForTotalQuantity(
            relevantQuantity
          )
          : 0
      const lineItem = {
        quantity: p.quantity,
        title: licenseMixin.methods.getNameOfShopProduct(p),
        vatRate: vatRateForProduct,
        itemBaseGross: licenseMixin.methods.getBasePriceOfShopProduct(p),
        itemBaseVat: null,
        itemBaseNet: null,
        itemVolumeDiscount: discount,
        itemGross: null,
        itemVat: null,
        itemNet: null,
        gross: null,
        vat: null,
        net: null,
        customerReference: p.customerReference ?? null,
        licenseUID: p.licenseUID ?? null
      }
      // TODO:  Equal rounding to shop
      lineItem.itemBaseVat =
        lineItem.itemBaseGross * (1 - 1 / (1 + lineItem.vatRate))
      lineItem.itemBaseNet = lineItem.itemBaseGross - lineItem.itemBaseVat

      lineItem.itemGross =
        Math.round(lineItem.itemBaseGross * factor * 100) / 100
      lineItem.itemVat = lineItem.itemGross * (1 - 1 / (1 + lineItem.vatRate))
      lineItem.itemNet = lineItem.itemGross - lineItem.itemVat

      lineItem.gross = lineItem.itemGross * lineItem.quantity
      lineItem.vat = lineItem.itemVat * lineItem.quantity
      lineItem.net = lineItem.itemNet * lineItem.quantity

      return lineItem
    })
  },
  groupedLineItems (state, getters) {
    const grouped = {}
    for (const lineItem of getters.cartLineItems) {
      const uid = lineItem.licenseUID ?? 'default'
      if (grouped[uid] == null) {
        grouped[uid] = {
          // TODO: Group with license properties
          uid,
          customerReference:
            lineItem.customerReference ??
            getters.getCustomerReferencePlaceholder(uid),
          lineItems: []
        }
      }

      grouped[uid].lineItems.push(lineItem)
    }
    return Object.values(grouped)
  },
  getCustomerReferencePlaceholder (state, getters) {
    return (uid) => {
      return `Lizenz ${state.resellerLicenses.findIndex((l) => l.uid === uid) + 1}`
    }
  },
  cartTotals (state, getters) {
    const totals = {
      gross: 0,
      net: 0,
      vat: 0,
      vatRate: 0
    }
    const lineItems = getters.cartLineItems
    lineItems.forEach((l) => {
      totals.gross += l.gross
      totals.net += l.net
      totals.vat += l.vat
      totals.vatRate = l.vatRate
    })

    totals.vat = Math.round(totals.vat * 100) / 100
    totals.net = Math.round(totals.net * 100) / 100
    return totals
  },
  purchasedLicenseProperties (state, getters) {
    return {
      delivery: 'download',
      class: state.licenseType ?? undefined,
      type:
        state.purchaseType === 'new'
          ? 'full'
          : state.purchaseType === 'upgrade'
            ? 'update'
            : state.authenticatedLicenses?.[0]?.license?.properties?.type,
      size:
        state.purchaseType !== 'extension'
          ? state.seatCount
          : getters.totalOldLicenseSeats + state.seatCount,
      dvdCount:
        state.purchaseType !== 'extension'
          ? state.dvdCount
          : (state.authenticatedLicenses?.[0]?.license?.properties?.dvdCount ??
              0) + state.dvdCount
    }
  },
  purchasedLicenseData (state, getters) {
    if (getters.isResellerOrder) {
      if (state.resellerLicenses.length === 0) {
        return null
      } else {
        return state.resellerLicenses
      }
    }

    if (state.toVersion == null || state.purchaseType == null) {
      return null
    }
    const properties = {
      version: state.toVersion,
      key:
        state.purchaseType === 'extension'
          ? state.authenticatedLicenses?.[0]?.license?.key
          : null,
      properties: getters.purchasedLicenseProperties,
      holder: {
        ...(state.session?.holder ?? {})
      }
    }

    return properties
  },

  sessionData (state, getters) {
    const originalLicenses = state.authenticatedLicenses.map(
      (d) => d.license._id
    )
    const accessTokens = state.authenticatedLicenses.map((d) => d.accessToken)

    return {
      purchaseType: state.purchaseType,
      originalLicenses,
      accessTokens,
      fromVersion: state.fromVersion ?? undefined,
      toVersion: state.toVersion,
      properties: getters.purchasedLicenseProperties,
      billing: state.billing,
      shipToDifferentAddress: state.shipToDifferentAddress,
      shipping: state.shipping,
      resellerLicenses: state.resellerLicenses.map((l) => {
        if (l.customerReference == null) {
          return {
            ...l,
            customerReference: getters.getCustomerReferencePlaceholder(l.uid)
          }
        }
        return l
      })
    }
  }
}

const mutations = {
  reset (state) {
    state.session = null
    state.sessionToken = null
    state.offerToken = null

    state.createError = null
    state.isLoadingCreate = false

    state.createOfferError = null
    state.isCreatingOffer = false

    state.loadOfferError = null
    state.isLoadingOffer = false

    state.patchError = null
    state.isLoadingPatch = false

    // State
    state.currentState = 'welcome'
    state.history = []

    // Session properties
    state.billing = {
      name: null,
      additional1: null,
      additional2: null,
      street: null,
      houseNumber: null,
      postcode: null,
      city: null,
      countryCode: 'DE',
      email: null,
      reference: null,
      note: null
    }
    state.shipToDifferentAddress = false
    state.shipping = {
      name: null,
      additional1: null,
      additional2: null,
      street: null,
      houseNumber: null,
      postcode: null,
      city: null,
      countryCode: null
    }

    state.fromVersion = null
    state.toVersion = null

    state.purchaseType = null // 'new', 'upgrade', 'extension'
    state.licenseType = null // 'personal' / 'pro'

    state.seatCount = 1 // Purchased seats
    state.dvdCount = 0 // Purchased additional dvds

    state.authenticatedLicenses = []
    state.resellerLicenses = [] // Purchasing multiple reseller licenses
  },
  addAuthenticatedLicense (state, { license, accessToken }) {
    const i = state.authenticatedLicenses.findIndex(
      (d) => d.license._id === license._id
    )
    if (i !== -1) {
      return
    }
    state.authenticatedLicenses.push({ license, accessToken })
  },
  removeAuthenticatedLicense (state, license) {
    const i = state.authenticatedLicenses.findIndex(
      (d) => d.license._id === license._id
    )
    if (i === -1) {
      return
    }
    state.authenticatedLicenses.splice(i)
  },
  updateState (state, { key, value }) {
    Vue.set(state, key, value)
    if (key === 'seatCount' || key === 'dvdCount') {
      state.seatCount = Math.max(state.seatCount, 0)
      state.dvdCount = Math.max(Math.min(state.seatCount, state.dvdCount), 0)
    }
  },
  updateBillingField (state, { key, value }) {
    state.billing[key] = value
  },

  addResellerLicense (state, options) {
    for (let i = 0; i < options.quantity; i++) {
      state.resellerLicenses.push({
        uid: 'reseller-license-' + uuidv4(),
        properties: {
          class: options.class,
          licenseType: 'full',
          size: options.size,
          delivery: 'download',
          dvdCount: options.dvdCount
        },
        version: '9',
        customerReference: options.customerReference ?? null
      })
    }
  },
  updateResellerLicenseProperties (state, { uid, data }) {
    const i = state.resellerLicenses.findIndex((l) => l.uid === uid)
    if (i === -1) {
      return
    }
    Vue.set(state.resellerLicenses, i, {
      ...state.resellerLicenses[i],
      properties: {
        ...state.resellerLicenses[i].properties,
        ...data
      }
    })
  },
  updateResellerLicense (state, { uid, data }) {
    const i = state.resellerLicenses.findIndex((l) => l.uid === uid)
    if (i === -1) {
      return
    }
    Vue.set(state.resellerLicenses, i, {
      ...state.resellerLicenses[i],
      ...data
    })
  },
  removeResellerLicense (state, uid) {
    const i = state.resellerLicenses.findIndex((l) => l.uid === uid)
    if (i === -1) {
      return
    }
    Vue.delete(state.resellerLicenses, i)
  },
  clearResellerCart (state) {
    state.resellerLicenses = []
  },
  updateStateFromSession (state, session) {
    state.session = session

    state.billing = session.billing
    state.shipToDifferentAddress = session.shipToDifferentAddress
    state.shipping = session.shipping
    state.fromVersion = session.fromVersion ?? null
    state.toVersion = session.toVersion
    state.purchaseType = session.purchaseType
    state.licenseType = session.properties.class
    state.dvdCount = session.properties.dvdCount
    state.authenticatedLicenses =
      session.originalLicenses?.map((l) => {
        return { license: l }
      }) ?? []

    state.seatCount = session.properties.size
    state.resellerLicenses = session.resellerLicenses

    if (state.purchaseType === 'extension') {
      state.seatCount -= session.originalLicenses[0].properties.size
      state.dvdCount -= session.originalLicenses[0].properties.dvdCount
    }
  },
  removeAllDVDs (state) {
    state.dvdCount = 0
    state.resellerLicenses?.forEach((l, i) => {
      Vue.set(l.properties, 'dvdCount', 0)
    })
  },
  updateBilling (state, data) {
    Object.keys(state.billing).forEach((key) => {
      if (state.billing[key] !== data[key]) {
        state.billing[key] = data[key]
      }
    })
  },
  updateShipping (state, data) {
    Object.keys(state.shipping).forEach((key) => {
      if (state.shipping[key] !== data[key]) {
        state.shipping[key] = data[key]
      }
    })
  }
}

const actions = {
  async loadOffer ({ state, getter, commit }, offerToken) {
    state.loadOfferError = null
    state.isLoadingOffer = true
    try {
      const response = await UpgradeSessionAPI.getByOfferToken(offerToken)
      commit('updateStateFromSession', response.data.data.session)
      state.offerToken = offerToken
      return response.data.data.session
    } catch (error) {
      console.error(error)
      state.loadOfferError = error
    } finally {
      state.isLoadingOffer = false
    }
  },

  async createSession ({ state, getters }) {
    state.createError = null
    state.isLoadingCreate = true
    try {
      const response = await UpgradeSessionAPI.createUpgradeSession(
        getters.sessionData
      )
      state.session = response.data.data.session
      state.sessionToken = response.data.data.token
    } catch (error) {
      console.error(error)
      state.createError = error
    } finally {
      state.isLoadingCreate = false
    }
  },

  async patchSession ({ state }, data) {
    state.patchError = null
    state.isLoadingPatch = true
    try {
      const response = await UpgradeSessionAPI.patchUpgradeSession(
        state.session._id,
        data,
        state.sessionToken
      )
      state.session = response.data.data.session
      state.isLoadingPatch = false
      return true
    } catch (error) {
      console.error(error)
      state.patchError = error
      state.isLoadingPatch = false
      return false
    }
  },

  async createOffer ({ state, dispatch }, data) {
    state.isCreatingOffer = true
    state.createOfferError = false
    try {
      if (!state.session) {
        await dispatch('createSession')
        await dispatch('patchSession', {
          status: 'offered'
        })
      } else {
        await dispatch('patchSession', {
          status: 'offered',
          billing: state.billing,
          shipping: state.shipping,
          shipToDifferentAddress: state.shipToDifferentAddress
        })
      }

      state.isCreatingOffer = false
    } catch (error) {
      state.isCreatingOffer = false
      state.createOfferError = error
      return false
    }

    return true
  },

  async maybeUpdateSessionProperties ({ state, getters, dispatch }) {
    if (state.session) {
      await dispatch('patchSession', {
        properties: getters.purchasedLicenseProperties
      })
    }
  },

  async transitionTo (vuexOptions, newState) {
    const { state, getters, dispatch } = vuexOptions
    const oldState = state.currentState
    state.history.push(oldState)
    state.currentState = newState

    const def = getters.currentStateDefinition
    window.scroll(0, 0)
    dispatch('scrollShopToTop')
    dispatch('adjustIFrameHeight')

    return def.onEnter?.(vuexOptions, oldState)
  },

  async goBack ({ state }) {
    state.currentState = state.history.pop()
  },

  async sendIf (vuexOptions, { condition, event }) {
    if (condition) {
      return vuexOptions.dispatch('send', event)
    }
  },
  async send (vuexOptions, event) {
    const { getters, dispatch } = vuexOptions
    const def = getters.currentStateDefinition
    let targetState = def.on?.[event]

    const handleRes = await def.handle?.[event]?.(vuexOptions, event)
    if (handleRes === false) {
      return
    }
    if (!targetState && handleRes) {
      targetState = handleRes
    }
    return dispatch('transitionTo', targetState)
  },

  scrollShopToTop () {
    window.parent?.postMessage(
      {
        event: 'scroll_to_top'
      },
      METACOM_SHOP_HOST
    )
  },

  adjustIFrameHeight () {
    const minHeight = 850
    const additional = 500
    let firstHeight
    setTimeout(() => {
      firstHeight = document.body.scrollHeight
      window.parent?.postMessage(
        {
          event: 'adjust_height',
          data: {
            height: Math.max(firstHeight + additional, minHeight)
          }
        },
        METACOM_SHOP_HOST
      )
    }, 20)
    setTimeout(() => {
      const laterHeight = document.body.scrollHeight
      if (firstHeight !== laterHeight) {
        window.parent?.postMessage(
          {
            event: 'adjust_height',
            data: {
              height: Math.max(laterHeight + additional, minHeight)
            }
          },
          METACOM_SHOP_HOST
        )
      }
    }, 100)
  },

  sendToShop ({ state, getters }) {
    const products = getters.shopProducts
    window.parent?.postMessage(
      {
        event: 'add_to_cart',
        data: {
          products,
          sessionId: state.session?._id,
          sessionToken: state.sessionToken,
          offerToken: state.offerToken,
          billing: state.billing,
          purchaseType: state.purchaseType
        }
      },
      METACOM_SHOP_HOST
    )
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

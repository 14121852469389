<template>
  <div class="reseller-cart-item p-2">
    <div class="license-image">
      <figure>
        <img
          width="48"
          height="48"
          :src="getLicenseImageOfLicense(license)"
        >
      </figure>
    </div>
    <span class="license-name">
      {{ getShortFormattedNameOfLicense(license) }}
    </span>
    <b-field label="Größe">
      <b-input
        type="number"
        class="license-size"
        :value="license.properties.size"
        :disabled="license.properties.class !== 'pro'"
        @input="onUpdateSeatCount"
      />
    </b-field>

    <b-field
      label="DVDs"
      :message="
        !canPurchaseDVDs
          ? 'DVDs können nur nach Deutschland geliefert werden.'
          : ''
      "
    >
      <!-- <b-checkbox
              :value="license.properties.dvdCount > 0"
              :disabled="!canPurchaseDVDs"
              @input="onToggleDvd"
            >
              DVD(s) mitbestellen
            </b-checkbox> -->
      <b-input
        ref="dvdCount"
        class="dvd-count"
        :disabled="license.properties.class === 'personal' || !canPurchaseDVDs"
        type="number"
        :value="license.properties.dvdCount"
        @input="onUpdateDvdCount"
      />
    </b-field>
    <b-field>
      <template #label>
        Referenz
        <b-tooltip
          type="is-dark"
          position="is-left"
          label="Hier können Sie eine Referenz hinzufügen, um die bestellten Lizenzen einfacher zu unterscheiden."
        >
          <b-icon
            size="is-small"
            icon="info-circle"
          />
        </b-tooltip>
      </template>
      <b-input
        :value="license.customerReference"
        :placeholder="getCustomerReferencePlaceholder(license.uid)"
        @input="onUpdateCustomerReference"
      />
    </b-field>
    <div class="license-actions">
      <b-button
        icon-left="times"
        type="is-danger"
        @click="onRemoveCartItem"
      />
    </div>
  </div>
</template>

<script>
import licenseMixin from '@/mixins/license'
import { mapGetters } from 'vuex'
export default {
  mixins: [licenseMixin],
  props: {
    license: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters('assistant', [
      'canPurchaseDVDs',
      'getCustomerReferencePlaceholder'
    ])
  },
  methods: {
    onToggleDvd () {
      const newDvdCount = this.license.properties.dvdCount > 0 ? 0 : 1
      this.$store.commit('assistant/updateResellerLicenseProperties', {
        uid: this.license.uid,
        data: { dvdCount: newDvdCount }
      })
      this.$store.dispatch('assistant/adjustIFrameHeight')
    },
    onUpdateSeatCount (value) {
      const parsedValue = Number.parseInt(value)
      if (isNaN(parsedValue)) {
        return
      }
      const newValue = Math.max(parsedValue, 0)
      this.$store.commit('assistant/updateResellerLicenseProperties', {
        uid: this.license.uid,
        data: { size: newValue }
      })
    },
    onUpdateDvdCount (value) {
      const parsedValue = Number.parseInt(value)

      if (isNaN(parsedValue)) {
        return
      }

      const newValue = Math.max(
        Math.min(parsedValue, this.license.properties.size),
        0
      )
      if (newValue !== parsedValue) {
        this.$refs.dvdCount.newValue = newValue
        this.$refs.dvdCount.$refs.input.value = newValue.toString()
        // this.$refs.dvdCount.$refs.input.blur()
        if (newValue < parsedValue) {
          this.$buefy.toast.open({
            type: 'is-warning',
            message: 'Die maximale DVD-Anzahl ist die Lizenz-Größe'
          })
        }
      }
      this.$store.commit('assistant/updateResellerLicenseProperties', {
        uid: this.license.uid,
        data: { dvdCount: newValue }
      })
    },
    onUpdateCustomerReference (value) {
      this.$store.commit('assistant/updateResellerLicense', {
        uid: this.license.uid,
        data: { customerReference: value }
      })
    },
    onRemoveCartItem () {
      this.$store.commit('assistant/removeResellerLicense', this.license.uid)
      this.$store.dispatch('assistant/adjustIFrameHeight')
    }
  }
}
</script>

<style lang="scss" scoped>
.reseller-cart-item {
  display: flex;
  gap: 1em;

  @media screen and (max-width: $tablet) {
    flex-wrap: wrap;
  }
  .field {
    margin-bottom: 0;
    flex-shrink: 0;
  }

  ::v-deep .license-size input.input,
  ::v-deep .dvd-count input.input {
    width: 4em;
  }

  .license-name {
    flex-grow: 1;
    font-weight: bold;
  }

  .license-image,
  .license-name {
    display: flex;
    align-items: center;
  }
  .license-actions {
  }
  .license-actions {
    padding-top: 2em;
  }
}
</style>

<template>
  <div class="reseller-cart">
    <div class="buttons">
      <b-button
        icon-left="plus"
        @click="onAddPersonalLicense()"
      >
        Persönliche Lizenz
      </b-button>
      <b-button
        icon-left="plus"
        @click="onAddProLicense()"
      >
        Pro-Lizenz
      </b-button>
      <b-dropdown
        ref="dropdown"
        position="is-bottom-left"
        append-to-body
        aria-role="menu"
        trap-focus
      >
        <template #trigger>
          <b-button icon-left="plus">
            Mehrere Lizenzen hinzufügen
          </b-button>
        </template>

        <b-dropdown-item
          aria-role="menu-item"
          :focusable="false"
          custom
          class="multiple-dropdown"
        >
          <div class="properties">
            <b-field label="Lizenz">
              <b-field>
                <b-select
                  v-model="licenseClass"
                  placeholder="Lizenzart"
                  @input="onLicenseClassChanged"
                >
                  <option value="personal">
                    Persönliche Lizenz
                  </option>
                  <option value="pro">
                    Pro-Lizenz
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <b-field label="Größe">
              <b-input
                v-model="size"
                type="number"
                min="1"
                step="1"
                max="100000"
                :disabled="licenseClass === 'personal'"
              />
            </b-field>

            <b-field label="DVDs">
              <b-input
                v-model="dvdCount"
                type="number"
                min="0"
                step="1"
                :max="size"
                :disabled="canPurchaseDVDs === false"
              />
            </b-field>
          </div>
          <div class="add-to-cart">
            <b-field
              label="Stückzahl"
              expanded
            >
              <b-input
                v-model="quantity"
                type="number"
                min="1"
                step="1"
                max="100000"
              />
              <p class="control is-fullwidth">
                <b-button
                  icon-left="shopping-cart"
                  type="is-primary"
                  expanded
                  @click="onAddResellerLicense()"
                >
                  Hinzufügen
                </b-button>
              </p>
            </b-field>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <reseller-cart-item
      v-for="license of resellerLicenses"
      :key="license.uid"
      :license="license"
    />
    <div class="buttons">
      <b-button
        v-if="resellerLicenses && resellerLicenses.length > 0"
        type="is-danger"
        icon-left="times"
        @click="onClearResellerCart()"
      >
        Alle entfernen
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ResellerCartItem from './ResellerCartItem.vue'

export default {
  components: { ResellerCartItem },
  data () {
    return {
      licenseClass: 'pro',
      size: 1,
      dvdCount: 0,
      quantity: 1
    }
  },
  computed: {
    ...mapState('assistant', ['resellerLicenses']),
    ...mapGetters('assistant', ['canPurchaseDVDs'])
  },
  watch: {
    canPurchaseDVDs (value, oldValue) {
      if (value === false) {
        this.dvdCount = 0
      }
    }
  },
  methods: {
    onAddProLicense () {
      this.$store.commit('assistant/addResellerLicense', {
        class: 'pro',
        dvdCount: 0,
        size: 1,
        quantity: 1
      })
      this.$store.dispatch('assistant/adjustIFrameHeight')
    },
    onAddPersonalLicense () {
      this.$store.commit('assistant/addResellerLicense', {
        class: 'personal',
        dvdCount: 0,
        size: 1,
        quantity: 1
      })
      this.$store.dispatch('assistant/adjustIFrameHeight')
    },
    onAddResellerLicense () {
      this.$store.commit('assistant/addResellerLicense', {
        class: this.licenseClass,
        dvdCount: this.dvdCount,
        size: this.size,
        quantity: this.quantity
      })
      this.$store.dispatch('assistant/adjustIFrameHeight')
      this.$refs.dropdown.toggle()
    },
    onClearResellerCart () {
      this.$store.commit('assistant/clearResellerCart')
    },
    onLicenseClassChanged () {
      if (this.licenseClass === 'personal') {
        this.size = 1
        this.dvdCount = Math.min(this.dvdCount, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.multiple-dropdown {
  .properties {
    display: flex;
    gap: 1em;

    > .field {
      > .field {
        flex-shrink: 1;
      }
    }

    ::v-deep input {
      min-width: 4em;
    }
  }
  .add-to-cart {
    display: flex;
    align-items: flex-end;
    > .field {
      margin-bottom: 0;
      width: 100%;
      p.control {
        flex-grow: 1;
      }
    }
  }
}
</style>
